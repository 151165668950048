import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  THead,
  TrHead,
  Th,
  TBody,
  InnerCell,
  RotaCheckbox,
  useToast,
  RotaButtonNew
} from "@teamrota/rota-design";
import { useSelectedItems } from "@teamrota/rota-design";
import { OptionKey, optionTranslations, TimesheetMemberStatus } from "../types";
import { useTimesheetBreakdown } from "../use-timesheet-breakdown";
import {
  SelectedItemsInfo,
  ActionsWrapper,
  RotaTableScrollable
} from "../style";

import { useTimesheetBreakdownCostDetails } from "../use-timesheet-breakdown-cost-details";
import { TIMESHEET_MEMBER_STATUS } from "@teamrota/rota-common";
import { useApproveTimesheetMembers } from "../use-approve-timesheet-members";
import { useDownloadTimesheetXlsx } from "../use-download-timesheet-xlsx";
import { TimesheetPdf } from "../timesheet-pdf";
import { TimesheetMemberWithStats } from "../use-timesheet-members-with-stats";
import Loader from "./Loader";
import styled from "styled-components";
import TimesheetBreakdownModalContentMember from "./TimesheetBreakdownModalContentMember";
import TimesheetCostDetailsSummary from "./TimesheetCostDetailsSummary";

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: end;
  margin-top: 16px;
`;

interface TimesheetBreakdownModalContentProps {
  timesheet: TimesheetMemberWithStats;
  selectedOptions: OptionKey[];
  options: OptionKey[];
}

const TimesheetBreakdownModalContent = ({
  timesheet,
  selectedOptions,
  options
}: TimesheetBreakdownModalContentProps) => {
  const { showToast } = useToast();
  const {
    downloadTimesheetXlsx,
    loading: loadingDownload
  } = useDownloadTimesheetXlsx();

  const {
    timesheetMembers,
    currencyCode,
    loading,
    refetch
  } = useTimesheetBreakdown({
    id: timesheet.timesheetId,
    memberId: timesheet.memberId
  });

  const {
    timesheetCostDetails,
    loading: loadingTimesheetCostDetails,
    refetch: refetchTimesheetCostDetails
  } = useTimesheetBreakdownCostDetails({
    id: timesheet.timesheetId
  });

  const {
    selectedItemsArray,
    hasSelectedAll,
    onToggleOneItem,
    onToggleAllItems,
    reset
  } = useSelectedItems(timesheetMembers.map(({ id }) => id));

  const isCheckAllBoxDisabled =
    loading || timesheet.status === TimesheetMemberStatus.APPROVED;

  const { approveTimesheetMembers } = useApproveTimesheetMembers({
    onCompleted: approvedCount => {
      showToast(
        `${approvedCount} ${
          approvedCount === 1 ? "Member" : "Members"
        } approved`
      );
      reset();
    }
  });

  if (loading && !timesheetMembers) return <Loader />;

  const timesheetMemberRowIds = selectedItemsArray.length
    ? selectedItemsArray.map(
        id =>
          timesheetMembers.find(timesheetMember => timesheetMember.id === id)
            ?.id
      )
    : timesheetMembers.map(({ id }) => id);

  const disableApproveButton =
    timesheet.status === TimesheetMemberStatus.APPROVED ||
    !selectedItemsArray.length ||
    !selectedItemsArray.some(
      id =>
        timesheetMembers.find(timesheetMember => timesheetMember.id === id)
          ?.status !== TIMESHEET_MEMBER_STATUS.APPROVED
    );

  const pdfReady =
    timesheet &&
    timesheetMembers &&
    !(loadingTimesheetCostDetails && !timesheetCostDetails);

  const isOnCallTimesEnabledOnShift = timesheet.isOnCallTimesEnabledOnShift;

  return (
    <>
      <ActionsWrapper>
        <RotaButtonNew
          disabled={disableApproveButton}
          onClick={() => {
            approveTimesheetMembers({
              timesheetMemberIds: selectedItemsArray
            });
          }}
        >
          Approve
        </RotaButtonNew>
        {!!selectedItemsArray.length && (
          <SelectedItemsInfo>{`Selected ${selectedItemsArray.length} timesheets`}</SelectedItemsInfo>
        )}
      </ActionsWrapper>
      <div style={{ overflow: "auto", flexGrow: 1 }}>
        <RotaTableScrollable>
          <THead>
            <TrHead>
              <Th>
                <InnerCell>
                  <RotaCheckbox
                    isDisabled={isCheckAllBoxDisabled}
                    isChecked={hasSelectedAll}
                    onClick={() => onToggleAllItems()}
                  />
                </InnerCell>
              </Th>
              <Th />
              <Th style={{ textAlign: "left", minWidth: 266 }}>Name</Th>
              <Th style={{ textAlign: "left", minWidth: 200 }}>Cancellation</Th>
              <Th style={{ minWidth: 180 }}>Feedback</Th>
              <Th style={{ minWidth: 180 }}>Role</Th>
              <Th style={{ minWidth: 200 }}>Start Date</Th>
              <Th style={{ minWidth: 200 }}>Start Time</Th>
              <Th style={{ minWidth: 200 }}>End Date</Th>
              <Th style={{ minWidth: 200 }}>End Time</Th>
              <Th style={{ minWidth: 180 }}>In/Out</Th>
              <Th>Total hours</Th>
              {isOnCallTimesEnabledOnShift && (
                <Th style={{ minWidth: 180 }}>Off-site On-call</Th>
              )}
              {isOnCallTimesEnabledOnShift && (
                <Th style={{ minWidth: 180 }}>On-site On-call</Th>
              )}
              {options.map(option => {
                if (!selectedOptions.includes(option)) return null;
                return (
                  <Th style={{ minWidth: 180 }} key={option}>
                    {optionTranslations[option]}
                  </Th>
                );
              })}
            </TrHead>
          </THead>
          <TBody>
            {timesheetMembers.map(timesheetMember => {
              return (
                <TimesheetBreakdownModalContentMember
                  key={timesheetMember.id}
                  timesheetMember={timesheetMember}
                  timesheet={timesheet}
                  currencyCode={currencyCode}
                  isChecked={selectedItemsArray.includes(timesheetMember.id)}
                  onToggleOneItem={onToggleOneItem}
                  selectedOptions={selectedOptions}
                  refetchData={() => {
                    refetch();
                    refetchTimesheetCostDetails();
                  }}
                />
              );
            })}
          </TBody>
        </RotaTableScrollable>
      </div>
      {!(loadingTimesheetCostDetails && !timesheetCostDetails) && (
        <TimesheetCostDetailsSummary
          isUncalculatedRole={timesheet.isUncalculatedRole}
          timesheetCostDetails={timesheetCostDetails}
          currencyCode={currencyCode}
        />
      )}
      <ButtonsWrapper>
        {pdfReady ? (
          <PDFDownloadLink
            document={
              <TimesheetPdf
                timesheet={timesheet}
                timesheetMembers={timesheetMembers}
                timesheetCostDetails={timesheetCostDetails}
                currencyCode={currencyCode}
              />
            }
            fileName={`timesheet_${timesheet.id}.pdf`}
          >
            {({ loading }: { loading: boolean }) => (
              <RotaButtonNew disabled={loading} isLoading={loading}>
                PDF
              </RotaButtonNew>
            )}
          </PDFDownloadLink>
        ) : (
          <RotaButtonNew disabled>PDF</RotaButtonNew>
        )}

        <RotaButtonNew
          isLoading={loadingDownload}
          onClick={() =>
            downloadTimesheetXlsx({
              timesheetId: timesheet.timesheetId,
              timesheetMemberRowIds: timesheetMemberRowIds.join(","),
              fields: selectedOptions.join(",")
            })
          }
        >
          Spreadsheet
        </RotaButtonNew>
      </ButtonsWrapper>
    </>
  );
};

export default TimesheetBreakdownModalContent;
