import React, { useState } from "react";
import moment from "moment-timezone";
import {
  Tr,
  Td,
  InnerCell,
  RotaCheckbox,
  RotaAvatar,
  RotaStatusLabel,
  RotaTextarea,
  RotaInput,
  useToast,
  RotaSelect
} from "@teamrota/rota-design";
import { getFormatterMoney } from "~/src/utils/formatting";
import {
  TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS,
  TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS
} from "@teamrota/rota-common";
import useVenues from "../use-venues";

import { OptionKey, Subvenue, TimesheetMemberStatus } from "../types";
import { MemberBreakdown } from "../use-timesheet-breakdown";
import { InnerCellTime } from "../style";
import {
  getMemberStatusType,
  getMemberPinIcon,
  memberStatusTranslations,
  convertMinutesToTimeString,
  cancellationOptions,
  getCancellation,
  Cancellation
} from "../utils";
import { useUpdateTimesheetMember } from "../use-update-timesheet-member";

import { TimesheetMemberWithStats } from "../use-timesheet-members-with-stats";
import TimeRangeEditor from "../time-range-editor";
import styled from "styled-components";
import ScheduledTimes from "../scheduled-times";

const Option = styled.option``;

const sortAlphabetically = (a: Subvenue, b: Subvenue) => {
  if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
    return -1;
  }

  if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
    return 1;
  }

  return 0;
};

interface TimesheetBreakdownModalContentMemberProps {
  timesheetMember: MemberBreakdown;
  timesheet: TimesheetMemberWithStats;
  selectedOptions: OptionKey[];
  currencyCode: string;
  isChecked: boolean;
  onToggleOneItem: (id: string) => void;
  refetchData: () => void;
}

const TimesheetBreakdownModalContentMember = ({
  timesheetMember,
  timesheet,
  selectedOptions,
  currencyCode,
  isChecked,
  onToggleOneItem,
  refetchData
}: TimesheetBreakdownModalContentMemberProps) => {
  const {
    id,
    name,
    memberAvatar,
    roleName,
    totalPay,
    totalCharge,
    payRate,
    chargeRate,
    status,
    feedbackForMember: initialFeedback,
    checkInTime,
    checkOutTime,
    totalMinutes,
    isNoShow,
    isTurnedAway,
    isInputError,
    isNoLongerRequired,
    isSickness,
    isAnnualLeave,
    isUnknown,
    isNone,
    scheduledStartTime,
    scheduledEndTime,
    subvenueId,

    plannedSleepStartTime,
    plannedSleepEndTime,
    plannedSleepTimeMinutes,

    sleepStartTime,
    sleepEndTime,
    sleepTimeMinutes,

    disturbedHoursStart,
    disturbedHoursEnd,
    disturbedHoursMinutes,

    onCallOffSiteStart,
    onCallOffSiteEnd,

    onCallOnSiteStart,
    onCallOnSiteEnd
  } = timesheetMember;
  const { showToast } = useToast();
  const venues = useVenues();

  const subvenues =
    venues.find(venue => venue.id === timesheet?.venueId)?.subvenues || [];
  const sortedSubvenues = [...subvenues].sort(sortAlphabetically);
  const subvenueList = [{ id: -1, name: "None" }, ...sortedSubvenues];

  const [selectedSubvenueId, setSelectedSubvenueId] = useState(subvenueId);

  const checkIn = checkInTime ? moment(checkInTime).format("HH:mm") : "--:--";
  const checkOut = checkOutTime
    ? moment(checkOutTime).format("HH:mm")
    : "--:--";

  const plannedSleepStart = plannedSleepStartTime
    ? moment(plannedSleepStartTime).format("HH:mm")
    : "--:--";
  const plannedSleepEnd = plannedSleepEndTime
    ? moment(plannedSleepEndTime).format("HH:mm")
    : "--:--";
  const actualSleepStart = sleepStartTime
    ? moment(sleepStartTime).format("HH:mm")
    : "--:--";
  const actualSleepEnd = sleepEndTime
    ? moment(sleepEndTime).format("HH:mm")
    : "--:--";
  const disturbedSleepStart = disturbedHoursStart
    ? moment(disturbedHoursStart).format("HH:mm")
    : "--:--";
  const disturbedSleepEnd = disturbedHoursEnd
    ? moment(disturbedHoursEnd).format("HH:mm")
    : "--:--";

  const isShiftFinished = moment().isAfter(timesheet.shiftEndTime);
  const isLateCheckIn =
    !checkInTime ||
    moment(checkInTime).isAfter(timesheet.shiftStartTime, "minutes");

  const isLateCheckOut =
    !checkOutTime ||
    moment(checkOutTime).isAfter(timesheet.shiftEndTime, "minutes");

  const [feedbackForMember, setFeedbackForMember] = useState(
    initialFeedback || ""
  );
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string>(
    timesheetMember.purchaseOrderNumber || ""
  );
  const [breakMinutes, setBreakMinutes] = useState<string>(
    timesheetMember.break.toString() || "0"
  );

  const formatterMoney = getFormatterMoney(currencyCode);
  const { updateTimesheetMember, loading } = useUpdateTimesheetMember({
    onCompleted: () => {
      refetchData();
      showToast("Timesheet member row was updated");
    }
  });

  const isDisabled =
    loading || TimesheetMemberStatus.APPROVED === timesheet.status;

  const isCheckboxDisabled =
    loading || TimesheetMemberStatus.APPROVED === timesheet.status;

  const isOnCallTimesEnabledOnShift = timesheet.isOnCallTimesEnabledOnShift;

  return (
    <>
      <Tr isActive={isChecked}>
        <Td>
          <InnerCell>
            <RotaCheckbox
              isDisabled={isCheckboxDisabled}
              isChecked={isChecked}
              onClick={() => onToggleOneItem(id)}
            />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>
            <RotaAvatar src={memberAvatar} />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell style={{ textAlign: "left" }}>{name}</InnerCell>
        </Td>
        <Td>
          <InnerCell>
            <RotaSelect
              value={getCancellation({
                isNoShow,
                isTurnedAway,
                isInputError,
                isNoLongerRequired,
                isSickness,
                isAnnualLeave,
                isUnknown,
                isNone
              })}
              onChange={({ target: { value } }) => {
                updateTimesheetMember({
                  id: timesheetMember.id,
                  isNoShow: value === Cancellation.NO_SHOW,
                  isTurnedAway: value === Cancellation.TURNED_AWAY,
                  isInputError: value === Cancellation.INPUT_ERROR,
                  isNoLongerRequired: value === Cancellation.NO_LONGER_REQUIRED,
                  isSickness: value === Cancellation.SICKNESS,
                  isAnnualLeave: value === Cancellation.ANNUAL_LEAVE,
                  isUnknown: value === Cancellation.UNKNOWN,
                  isNone: value === Cancellation.NONE
                });
              }}
            >
              {cancellationOptions.map(option => (
                <Option value={option.value} key={option.value}>
                  {option.label}
                </Option>
              ))}
            </RotaSelect>
          </InnerCell>
        </Td>
        <Td>
          <InnerCell style={{ textAlign: "left" }}>
            <RotaTextarea
              placeholder="Feedback"
              value={feedbackForMember}
              onChange={e => setFeedbackForMember(e.target.value)}
              onBlur={() => {
                updateTimesheetMember({ id, feedbackForMember });
              }}
              isDisabled={isDisabled}
              wrapperStyles={{ margin: 0 }}
            />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>{roleName}</InnerCell>
        </Td>
        <ScheduledTimes
          id={id}
          scheduledEndTime={scheduledEndTime}
          scheduledStartTime={scheduledStartTime}
          loading={loading}
          updateTimesheetMember={updateTimesheetMember}
        />
        <Td>
          <InnerCellTime>
            {getMemberPinIcon({
              status,
              isLateCheckIn,
              isLateCheckOut: isShiftFinished && isLateCheckOut,
              isChecked
            })}
            {`${checkIn} - ${checkOut}`}
          </InnerCellTime>
        </Td>
        <Td>
          <InnerCell>{convertMinutesToTimeString(totalMinutes)}</InnerCell>
        </Td>
        {isOnCallTimesEnabledOnShift && (
          <Td>
            <InnerCell>
              <TimeRangeEditor
                startTime={onCallOffSiteStart}
                endTime={onCallOffSiteEnd}
                isDisabled={isDisabled} // Prevents editing when status isAPPROVED
                onUpdateTime={({ startTime, endTime }) =>
                  updateTimesheetMember({
                    id,
                    onCallOffSiteStart: startTime,
                    onCallOffSiteEnd: endTime
                  })
                }
              />
            </InnerCell>
          </Td>
        )}
        {isOnCallTimesEnabledOnShift && (
          <Td>
            <InnerCell>
              <TimeRangeEditor
                startTime={onCallOnSiteStart}
                endTime={onCallOnSiteEnd}
                isDisabled={isDisabled}
                onUpdateTime={({ startTime, endTime }) =>
                  updateTimesheetMember({
                    id,
                    onCallOnSiteStart: startTime,
                    onCallOnSiteEnd: endTime
                  })
                }
              />
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.STATUS
        ) && (
          <Td>
            <InnerCell>
              <RotaStatusLabel
                type={getMemberStatusType(status, isNoShow, isTurnedAway)}
              >
                {memberStatusTranslations[status]}
              </RotaStatusLabel>
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes("SUBVENUE") && (
          <Td>
            <InnerCell>
              <RotaSelect
                value={selectedSubvenueId}
                onChange={({ target: { value } }) => {
                  updateTimesheetMember({
                    id: timesheetMember.id,
                    subvenueId: Number(value)
                  });
                  setSelectedSubvenueId(value);
                }}
              >
                {subvenueList.map(subvenue => (
                  <Option value={subvenue.id} key={subvenue.id}>
                    {subvenue.name}
                  </Option>
                ))}
              </RotaSelect>
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.BREAK
        ) && (
          <Td>
            <InnerCell>
              <RotaInput
                placeholder="Break"
                isDisabled={isDisabled}
                value={breakMinutes}
                onChange={e => setBreakMinutes(e.target.value)}
                saveOnBlur={({ value }) => {
                  updateTimesheetMember({
                    id,
                    breakMinutes: value
                  });
                }}
              />
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.TOTAL_PAY
        ) && (
          <Td>
            <InnerCell>
              {timesheet.isUncalculatedRole
                ? "N/A"
                : formatterMoney.format(totalPay / 100)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.PAY_RATE
        ) && (
          <Td>
            <InnerCell>
              {timesheet.isUncalculatedRole
                ? "N/A"
                : formatterMoney.format(payRate / 100)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.CHARGE_RATE
        ) && (
          <Td>
            <InnerCell>
              {timesheet.isUncalculatedRole
                ? "N/A"
                : formatterMoney.format(chargeRate / 100)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.TOTAL_CHARGE
        ) && (
          <Td>
            <InnerCell>
              {timesheet.isUncalculatedRole
                ? "N/A"
                : formatterMoney.format(totalCharge / 100)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.PURCHASE_ORDER_NUMBER
        ) && (
          <Td>
            <InnerCell>
              <RotaInput
                placeholder="1231231"
                isDisabled={isDisabled}
                value={purchaseOrderNumber}
                onChange={e => setPurchaseOrderNumber(e.target.value)}
                saveOnBlur={({ value }) => {
                  updateTimesheetMember({
                    id,
                    purchaseOrderNumber: value
                  });
                }}
              />
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.PLANNED_SLEEP_TIME
        ) && (
          <Td>
            <InnerCell>{`${plannedSleepStart} - ${plannedSleepEnd}`}</InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.PLANNED_SLEEP_TIME_TOTAL
        ) && (
          <Td>
            <InnerCell>
              {convertMinutesToTimeString(plannedSleepTimeMinutes)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.ACTUAL_SLEEP_TIME
        ) && (
          <Td>
            <InnerCell>{`${actualSleepStart} - ${actualSleepEnd}`}</InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.ACTUAL_SLEEP_TIME_TOTAL
        ) && (
          <Td>
            <InnerCell>
              {convertMinutesToTimeString(sleepTimeMinutes)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.DISTURBED_SLEEP_TIME
        ) && (
          <Td>
            <InnerCell>{`${disturbedSleepStart} - ${disturbedSleepEnd}`}</InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.DISTURBED_SLEEP_TIME_TOTAL
        ) && (
          <Td>
            <InnerCell>
              {convertMinutesToTimeString(disturbedHoursMinutes)}
            </InnerCell>
          </Td>
        )}
      </Tr>
    </>
  );
};

export default TimesheetBreakdownModalContentMember;
