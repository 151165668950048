import React from "react";
import moment from "moment-timezone";
import { Currency } from "@teamrota/rota-common/dist/currency-support/index";
import { TIMESHEET_MEMBER_STATUS } from "@teamrota/rota-common";

import {
  StyledPin,
  StyledPinSuccess,
  StyledPinWarn,
  StyledPinError
} from "./style";

export const currencySymbol = {
  [Currency.GBP]: "£",
  [Currency.EUR]: "€"
};

export const getMemberStatusType = (
  status: keyof typeof TIMESHEET_MEMBER_STATUS,
  isTurnedAway = false,
  isNoShow = false
) => {
  if (
    (isTurnedAway || isNoShow) &&
    TIMESHEET_MEMBER_STATUS.APPROVED === status
  ) {
    return "error";
  }

  if (TIMESHEET_MEMBER_STATUS.REJECTED === status) {
    return "error";
  }

  if (TIMESHEET_MEMBER_STATUS.APPROVED === status) {
    return "success";
  }

  return "warning";
};

interface GetMemberPinIconParams {
  status: keyof typeof TIMESHEET_MEMBER_STATUS;
  isLateCheckIn: boolean;
  isLateCheckOut: boolean;
  isChecked?: boolean;
}

export const getMemberPinIcon = ({
  status,
  isLateCheckIn,
  isLateCheckOut,
  isChecked = false
}: GetMemberPinIconParams) => {
  if (isLateCheckIn || isLateCheckOut) return <StyledPinError />;
  if (
    [
      TIMESHEET_MEMBER_STATUS.APPROVED,
      TIMESHEET_MEMBER_STATUS.FINISHED
    ].includes(status)
  )
    return <StyledPinSuccess />;
  if ([TIMESHEET_MEMBER_STATUS.TBC].includes(status))
    return <StyledPinWarn isChecked={isChecked} />;
  if ([TIMESHEET_MEMBER_STATUS.STARTED].includes(status)) return <StyledPin />;
  return <StyledPinError />;
};

export const memberStatusTranslations = {
  [TIMESHEET_MEMBER_STATUS.APPROVED]: "Approved",
  [TIMESHEET_MEMBER_STATUS.FINISHED]: "Finished",
  [TIMESHEET_MEMBER_STATUS.LATE]: "Late",
  [TIMESHEET_MEMBER_STATUS.TBC]: "TBC",
  [TIMESHEET_MEMBER_STATUS.STARTED]: "Started",
  [TIMESHEET_MEMBER_STATUS.REJECTED]: "Rejected"
};

export const timesheetStatusTranslations = {
  [TIMESHEET_MEMBER_STATUS.APPROVED]: "Approved",
  [TIMESHEET_MEMBER_STATUS.REJECTED]: "Rejected"
};

export function convertMinutesToTimeString(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.round(minutes % 60);
  return `${hours} h ${remainingMinutes} min`;
}

export function convertHoursToTimeString(hours: number): string {
  const minutes = hours * 60;
  return convertMinutesToTimeString(minutes);
}

export enum Cancellation {
  NO_SHOW = "isNoShow",
  TURNED_AWAY = "isTurnedAway",
  INPUT_ERROR = "isInputError",
  NO_LONGER_REQUIRED = "isNoLongerRequired",
  SICKNESS = "isSickness",
  ANNUAL_LEAVE = "isAnnualLeave",
  UNKNOWN = "isUnknown",
  NONE = "none"
}

export const cancellationOptions = [
  { label: "------- None -------", value: Cancellation.NONE, isPreview: true },
  { label: "Annual leave", value: Cancellation.ANNUAL_LEAVE, isPreview: false },
  { label: "Input error", value: Cancellation.INPUT_ERROR, isPreview: false },
  {
    label: "No longer required",
    value: Cancellation.NO_LONGER_REQUIRED,
    isPreview: false
  },
  { label: "No show", value: Cancellation.NO_SHOW, isPreview: true },
  { label: "Sickness", value: Cancellation.SICKNESS, isPreview: false },
  { label: "Turned away", value: Cancellation.TURNED_AWAY, isPreview: true },
  { label: "Unknown", value: Cancellation.UNKNOWN, isPreview: false }
];

export const getCancellation = ({
  isNoShow,
  isTurnedAway,
  isInputError,
  isNoLongerRequired,
  isSickness,
  isAnnualLeave,
  isUnknown,
  isNone
}: {
  isNoShow: boolean;
  isTurnedAway: boolean;
  isInputError: boolean;
  isNoLongerRequired: boolean;
  isSickness: boolean;
  isAnnualLeave: boolean;
  isUnknown: boolean;
  isNone: boolean;
}) => {
  if (isNoShow) return Cancellation.NO_SHOW;
  if (isTurnedAway) return Cancellation.TURNED_AWAY;
  if (isInputError) return Cancellation.INPUT_ERROR;
  if (isNoLongerRequired) return Cancellation.NO_LONGER_REQUIRED;
  if (isSickness) return Cancellation.SICKNESS;
  if (isAnnualLeave) return Cancellation.ANNUAL_LEAVE;
  if (isUnknown) return Cancellation.UNKNOWN;
  if (isNone) return Cancellation.NONE;
  return Cancellation.NONE;
};

export const calculateCheckInOutTimes = (
  isPayCalculationOnCheckInOutTimes: boolean,
  {
    shiftStartTime,
    shiftEndTime
  }: { shiftStartTime: string; shiftEndTime: string },
  checkInTime: string | undefined,
  checkOutTime: string | undefined
) => {
  const checkIn = !isPayCalculationOnCheckInOutTimes
    ? moment(shiftStartTime).format("HH:mm")
    : checkInTime
    ? moment(checkInTime).format("HH:mm")
    : "--:--";

  const checkOut = !isPayCalculationOnCheckInOutTimes
    ? moment(shiftEndTime).format("HH:mm")
    : checkOutTime
    ? moment(checkOutTime).format("HH:mm")
    : "--:--";

  return { checkIn, checkOut };
};

export const changeDateTimeToDateString = (date: any) =>
  moment(date)
    .format("YYYY-MM-DD")
    .toString();

export const changeDateTimeToTimeString = (date: any) =>
  moment(date)
    .format("HH:mm")
    .toString();
